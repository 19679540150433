import React from "react";
import { Navbar, Nav } from "react-bootstrap";

import "./Navbar.css";

const NavBar = props => (
  <Navbar collapseOnSelect expand="md" fixed="top">
    <Navbar.Brand href="#Home">Chloé Rice, Web Developer</Navbar.Brand>
    <Navbar.Toggle aria-controls="navbar-collapse" />
    <Navbar.Collapse id="navbar-collapse">
      <Nav>
        <Nav.Item>
          <Nav.Link eventKey={1} href="#About">
            ABOUT
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={2} href="#Projects">
            PROJECTS
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={3} href="#Contact">
            CONTACT
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default NavBar;
