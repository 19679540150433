import React from 'react'
import './Footer.css'

export const GitHub = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="9.5 9.5 48 48">
    <path 
      d="M23.966 34.047c-.042 0 .456 1.058.47 1.058 1.086 1.95 3.18 3.16 6.714 3.497-.504.378-1.11 1.096-1.194 1.925-.634.405-1.91.54-2.9.23-1.39-.433-1.92-3.15-4-2.763-.45.083-.362.377.028.626.634.406 1.23.913 1.69 1.993.355.83 1.098 2.31 3.448 2.31.932 0 1.586-.11 1.586-.11s.018 2.115.018 2.938c0 .95-1.296 1.22-1.296 1.68 0 .18.43.2.774.2.68 0 2.098-.56 2.098-1.55 0-.78.013-3.42.013-3.88 0-1.002.546-1.32.546-1.32s.07 5.376-.13 6.1c-.23.846-.65.726-.65 1.104 0 .56 1.71.14 2.27-1.1.44-.96.25-6.234.25-6.234l.47-.01s.03 2.416.01 3.52c-.013 1.144-.092 2.59.596 3.27.45.45 1.914 1.236 1.914.517 0-.417-.8-.76-.8-1.89V40.95c.64 0 .542 1.71.542 1.71l.047 3.18s-.146 1.157 1.264 1.64c.495.173 1.56.218 1.61-.07.05-.29-1.28-.72-1.295-1.615-.01-.547.024-.866.024-3.243s-.323-3.255-1.45-3.955c3.475-.352 5.625-1.2 6.67-3.475.084.002.43-1.062.384-1.062.236-.858.363-1.874.39-3.077-.008-3.26-1.588-4.414-1.89-4.956.446-2.47-.077-3.594-.318-3.98-.897-.314-3.116.81-4.33 1.6-1.976-.57-6.154-.514-7.72.15-2.89-2.046-4.42-1.733-4.42-1.733s-.99 1.75-.26 4.313c-.952 1.197-1.66 2.044-1.66 4.29-.002 1.266.15 2.4.49 3.39zm9.598 22.803c-12.767 0-23.117-10.406-23.117-23.243s10.35-23.243 23.117-23.243S56.68 20.77 56.68 33.607 46.33 56.85 33.565 56.85z"
    />
  </svg>
)

export const Twitter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="9.5 9.5 48 48">
    <path 
      d="M36.76 24.52c-2 .73-3.266 2.615-3.122 4.676l.048.795-.8-.09c-2.915-.372-5.46-1.64-7.622-3.765l-1.057-1.055-.272.78c-.576 1.735-.208 3.57.993 4.803.64.68.496.78-.608.373-.384-.13-.72-.225-.753-.18-.112.117.272 1.594.576 2.178.416.813 1.265 1.608 2.194 2.08l.785.372-.928.016c-.896 0-.928.017-.832.36.32 1.055 1.585 2.175 2.994 2.663l.993.34-.87.52c-1.28.748-2.788 1.17-4.29 1.202-.72.014-1.317.08-1.317.13 0 .16 1.953 1.07 3.09 1.426 3.41 1.056 7.46.6 10.503-1.2 2.162-1.28 4.323-3.83 5.332-6.3.544-1.315 1.09-3.717 1.09-4.87 0-.746.046-.843.942-1.736.528-.52 1.024-1.09 1.12-1.25.16-.31.144-.31-.672-.034-1.36.487-1.552.422-.88-.308.497-.52 1.09-1.46 1.09-1.737 0-.046-.24.034-.513.18-.29.16-.93.407-1.41.55l-.864.28-.784-.536c-.43-.294-1.04-.62-1.36-.716-.82-.226-2.07-.194-2.805.065zm-3.184 31.97c-12.66 0-22.924-10.292-22.924-22.988s10.264-22.988 22.924-22.988S56.5 20.802 56.5 33.5 46.236 56.488 33.576 56.488z"
    />
  </svg>
)

export const Instagram = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <path d="M31.155 19.05v-.005c.387 0 .776.007 1.163-.002.5-.013.905-.44.905-.938v-2.228c0-.533-.422-.957-.954-.957h-2.23c-.53 0-.96.425-.96.958 0 .736-.008 1.472 0 2.208 0 .11.02.224.054.33.133.39.49.63.932.633h1.072zM24 20.004c-2.61-.002-4.734 2.097-4.77 4.693-.037 2.667 2.1 4.78 4.66 4.834 2.667.06 4.823-2.07 4.88-4.65.056-2.662-2.1-4.87-4.77-4.87zm-9.225 2.54V33.05c0 .5.434.93.936.93h16.58c.51 0 .94-.43.94-.934v-10.5h-2.25c.32 1.012.42 2.043.29 3.094-.12 1.052-.46 2.03-1.01 2.932-.55.903-1.27 1.654-2.143 2.25-2.27 1.545-5.264 1.68-7.675.33-1.22-.683-2.176-1.634-2.844-2.863-1-1.83-1.157-3.75-.57-5.75H14.78zM33.42 36.53c.122-.02.244-.037.366-.064.962-.204 1.748-1.006 1.936-1.97.02-.107.035-.21.052-.316V15.363c-.017-.102-.03-.206-.05-.31-.207-1.053-1.078-1.87-2.146-2.017-.046-.005-.087-.015-.13-.023H14.554c-.113.02-.228.036-.34.062-1.045.237-1.815 1.076-1.965 2.132l-.03.13v18.867l.06.36c.23 1.02 1.1 1.807 2.137 1.942.05.006.1.016.15.023l18.84-.01zM24 47.122C11.214 47.122.85 36.772.85 24S11.213.877 24 .877 47.15 11.227 47.15 24 36.787 47.122 24 47.122z"/>
  </svg>
)

export const LinkedIn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <path d="M25.41 20.173v-.048c-.01.016-.024.033-.033.048h.032z"/>
    <path 
      d="M24 1.123C11.143 1.123.72 11.365.72 24 .72 36.634 11.143 46.877 24 46.877S47.28 36.635 47.28 24C47.28 11.366 36.857 1.123 24 1.123zm-6.41 31.774h-5.035v-14.88h5.034v14.88zm-2.517-16.912h-.034c-1.69 0-2.785-1.144-2.785-2.573 0-1.46 1.127-2.57 2.85-2.57 1.722 0 2.78 1.11 2.815 2.57 0 1.43-1.092 2.573-2.847 2.573zm20.67 16.912H30.71v-7.962c0-2-.73-3.365-2.55-3.365-1.392 0-2.22.92-2.585 1.81-.133.317-.167.76-.167 1.205v8.31h-5.035s.067-13.484 0-14.88h5.035v2.11c.67-1.013 1.864-2.46 4.538-2.46 3.314 0 5.797 2.127 5.798 6.698v8.534z"
    />
  </svg>
)

export const Mail = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <path d="M23.998 0C10.746 0 0 10.745 0 24.002 0 37.254 10.746 48 23.998 48 37.255 48 48 37.254 48 24.002 48 10.746 37.254 0 23.998 0zm15.148 13.7L24.53 24.33 8.588 13.564l30.56.138zm.88 20.51H7.974V14.825l16.62 11.34 15.432-11.14V34.21z"/>
  </svg>
)
