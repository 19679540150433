import React from "react";
import classnames from "classnames";

/* See the Bootstrap v4 docs for details on the new visibility API 
https://getbootstrap.com/docs/4.3/utilities/display/#hiding-elements */

// Hidden on all.d - none
// Visible on all.d - block

// Hidden only on xs:  .d - none .d - sm - block
// Visible only on xs:  .d - block .d - sm - none

// Hidden only on sm:  .d - sm - none .d - md - block
// Visible only on sm:  .d - none .d - sm - block.d - md - none

// Hidden only on md:  .d - md - none .d - lg - block
// Visible only on md:  .d - none .d - md - block.d - lg - none

// Hidden only on lg:  .d - lg - none .d - xl - block
// Visible only on lg:  .d - none .d - lg - block.d - xl - none

// Hidden only on xl:  .d - xl - none
// Visible only on xl:  .d - none .d - xl - block

const Display = ({ children, mobile, tablet, desktop }) => {
  const displayClassName = classnames(
    ".Display",
    /** xs, sm */
    mobile && "mobile d-sm-flex d-md-none",
    /** md */
    tablet && "tablet d-none d-md-flex d-lg-none",
    /** lg, xl */
    desktop && "desktop d-none d-lg-flex d-xl-flex"
  );

  return <div className={displayClassName}>{children}</div>;
};

export default Display;
