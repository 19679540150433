import React from "react";
import { Container } from "react-bootstrap";

import "./Portfolio.css";
import NavBar from "../navbar/Navbar";
import Hero from "../hero/Hero";
import About from "../about/About";
import Projects from "../projects/Projects";
import Footer from "../footer/Footer";

const Portfolio = props => (
  <div>
    <NavBar />
    <Container fluid>
      <Hero />
      <About />
      <Projects />
      <Footer />
    </Container>
  </div>
);

export default Portfolio;
