import React, { Component } from "react";
import { Row, Col, Image } from "react-bootstrap";

import Display from "../display";

import meEyesForward from "../../img/me-eyes-forward.svg";
import meEyesLooking from "../../img/me-eyes-looking.svg";
import macBook from "../../img/macbook.svg";
import mobileHero from "../../img/mobile-hero.svg";
import "./Hero.css";

export default class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false
    };
  }

  chloeLookRight = event => {
    this.setState({ hovering: true });
  };

  chloeLookForward = event => {
    this.setState({ hovering: false });
  };

  render() {
    return (
      <Row id="Home">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {/* DESKTOP */}
          <Display desktop>
            <Row>
              <Col className="Hero-illustration" lg={6} xl={6}>
                {this.state.hovering ? (
                  <Image
                    fluid
                    className="me-eyes-looking"
                    src={meEyesLooking}
                    alt="An illustrated self-portrait of Chloé."
                  />
                ) : (
                  <Image
                    fluid
                    className="me-eyes-forward"
                    src={meEyesForward}
                    alt="An illustrated self-portrait of Chloé."
                  />
                )}
              </Col>
              <Col className="Hero-illustration" lg={6} xl={6}>
                <Image
                  onMouseEnter={this.chloeLookRight}
                  onMouseLeave={this.chloeLookForward}
                  onTouchStart={this.chloeLookRight}
                  onTouchEnd={this.chloeLookForward}
                  className="Hero-illustration"
                  fluid
                  src={macBook}
                  alt="An interactive illustration of Chloe and her MacBook."
                />
                <h2 className="Hero-text">Oh hai, I'm Chloé!</h2>
                <h2 className="Hero-text">
                  I build delightfully interactive user experiences for the web.
                  My specialities are Node.js and React.js.
                </h2>
              </Col>
            </Row>
          </Display>
          {/* TABLET */}
          <Display tablet>
            <Row>
              <Col md={6}>
                <Image
                  fluid
                  className="me-eyes-forward"
                  src={meEyesForward}
                  alt="An illustrated self-portrait of Chloé."
                />
              </Col>
              <Col md={6}>
                <Image
                  fluid
                  src={macBook}
                  alt="An interactive illustration of Chloe and her MacBook."
                  className="tablet-Hero-illustration"
                  onMouseEnter={this.chloeLookRight}
                  onMouseLeave={this.chloeLookForward}
                  onTouchStart={this.chloeLookRight}
                  onTouchEnd={this.chloeLookForward}
                />
              </Col>
              <Col md={12}>
                <h2 className="tablet Hero-text">Oh hai, I'm Chloé!</h2>
                <h2 className="tablet Hero-text">
                  I build delightfully interactive user experiences for the web.
                  My specialities are Node.js and React.js.
                </h2>
              </Col>
            </Row>
          </Display>
          {/* MOBILE*/}
          <Display mobile>
            <Row>
              <Col className="mobile-Hero-illustration" xs="auto">
                <Image
                  fluid
                  src={mobileHero}
                  alt="An interactive illustration of Chloe and her MacBook."
                />
                <h2 className="mobile Hero-text">Oh hai, I'm Chloé!</h2>
                <h2 className="mobile Hero-text">
                  I build delightfully interactive user experiences for the web.
                  My specialities are Node.js and React.js.
                </h2>
              </Col>
            </Row>
          </Display>
        </Col>
      </Row>
    );
  }
}
